import React, { useState } from "react";
import styled from "styled-components";
import Text from "../editorial/Text";

const Comp = styled.input`
  height: 3.75rem; // 60px
  padding: 0 30px;
  outline: none;
  border: 2px solid ${p => p.error ? p.theme.colors.error : p.theme.colors.main01};
  background-color: ${p => p.error ? p.theme.colors.errorPale : p.theme.colors.titanWhite};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.text.medium.size.regular};
  font-family: ${({ theme }) => theme.text.medium.family}, sans-serif;
  color: ${({ theme }) => theme.colors.main01};
  //max-width: 40rem;
  //width: 100%;
  width: 25rem;
  margin-bottom: 1rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.periwinkleGray};
    opacity: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    height: 2.5rem; // 40px
    font-size: ${({ theme }) => theme.text.medium.size.small};
    width: 100%;
  }
`;

const ErrorText = styled(Text)`
  //margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.error};
`;

const InputItem = (props) => {
		const { errors, type } = props;
		const [error, setError] = useState(null);
		return (
			<>
				<Comp {...props}
							onInvalid={e => {
								setError(true);
							}}
							onInput={e => setError(false)}
							error={error}
							pattern={type === "email" ?
								"[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"//eslint-disable-line
								// : "^[\p{L}]${1,25}"
								: null
							}
				/>
				{error ?
					<ErrorText s="smaller">
						{type === "email" ? errors.emailError : errors.missing}
					</ErrorText>
					: null}
			</>
		);
	}
;

export default InputItem;
