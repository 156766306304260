import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Spacer from "../layout/Spacer";
import ImInput from "./IMInput";
import Block from "../editorial/Block";
import PillButton from "../buttons/PillButton";
import ImRadio from "./IMRadio";
import ImCheckboxes from "./IMCheckboxes";
import Conditional from "../layout/Conditional";
import ImTextarea from "./IMTextarea";
import ConsentCheck from "./ConsentCheck";


const SubscribeMessage = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.titanWhite};
  padding: 2.5rem;
`;

const ImForm = (props) => {
	const { data, gen } = props;
	const [conditionArr, setConditionArr] = useState([]);
	const [canSubmit, setCanSubmit] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	function changeCanSubmit(ID, value) {
		let newArr = [...conditionArr];
		// if the object is not in the array
		if (!newArr.find(i => i.id === ID)) {
			// add it
			newArr.push({ id: ID, checked: value });
		} else { // change it
			newArr.find(i => i.id === ID).checked = value;
		}

		setConditionArr(newArr);
		setCanSubmit(conditionArr.every(i => i.checked === true));
	}

	const content = data.content.map((item, index) => {
		let ComponentType;
		let data;
		let spacer;

		switch (item.type) {
			case "input":
				ComponentType = ImInput;
				data = {
					ID: item.ID,
					inputs: item.inputs,
					hasTitle: item.hasTitle,
					title: item.title,
					required: item.required,
					type: item.type,
					submitted: submitted,
					errors: {
						missing: gen.missingData,
						wrongData: gen.wrongData,
						emailError: gen.emailError
					}
				};
				spacer = <>
					<Conditional desktop>
						<Spacer s="medium" />
					</Conditional>
					<Conditional mobile>
						<Spacer s="medium" />
					</Conditional>
				</>;
				break;
			case "radio":
				ComponentType = ImRadio;
				data = {
					ID: item.ID,
					required: item.required,
					options: item.options,
					hasTitle: item.hasTitle,
					title: item.title,
					submitted: submitted
				};
				break;
			case "checkboxes":
				ComponentType = ImCheckboxes;
				data = {
					ID: item.ID,
					required: item.required,
					options: item.options,
					hasTitle: item.hasTitle,
					title: item.title,
					setCanSubmit: changeCanSubmit,
					submitted: submitted,
					errors: {
						checkboxesError: gen.checkboxesError
					}
				};
				break;
			case "textArea":
				ComponentType = ImTextarea;
				data = {
					ID: item.ID,
					required: item.required,
					options: item.options,
					hasTitle: item.hasTitle,
					title: item.title,
					placeholder: item.textAreaPlaceholder,
					setCanSubmit: setCanSubmit,
					submitted: submitted
				};
				spacer = <>
					<Conditional desktop>
						<Spacer s="medium" />
					</Conditional>
					<Conditional mobile>
						<Spacer s="medium" />
					</Conditional>
				</>;
				break;

			default:
				ComponentType = Spacer;
				data = null;
				spacer = null;
		}

		return (
			<React.Fragment key={index}>
				<ComponentType data={data} />
				{spacer}
			</React.Fragment>
		);
	});
	// console.log(content);

	useEffect(() => {
		const script = document.createElement("script");

		script.src = data.formLink;
		script.async = true;
		document.body.appendChild(script);
	}, [data.formLink]);

	// console.log(data);
	const handleAddToSpreadsheet = async (event) => {
		// event.preventDefault();

		// transform values to usable object
		const values = data.content.map((item) => {
			if (item.type === "input") {
				const inputItems = item.inputs.map(input => {
					return {
						key: input.ID,
						value: event.target[`inf[${input.ID}]`] ? event.target[`inf[${input.ID}]`].value : null
					};
				});
				return inputItems;
			} else {
				return { key: item.ID, value: event.target[`inf[${item.ID}]`] ? event.target[`inf[${item.ID}]`].value : null };
			}
		}).flat().reduce(
			(obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

		console.log(values);

		// const newRow = { IP: "new name", Email: "new value" };
		//
		// POST request
		await fetch("/api/googleSheets", {
			method: "POST",
			body: JSON.stringify({
				values: values,
				form: data.webformID
			}),
			headers: {
				"Content-Type": "application/json"
			}
		});

		// console.log(response);
	};

	return (
		// <FormProvider content={data.content}>
		<form method="post"
					action="https://newsletter.infomaniak.com/external/submit"
					className="inf-form"
					target="_blank"
					id={data.webformID}
					onSubmit={handleAddToSpreadsheet}
			// acceptCharset="utf-8"
		>
			<input type="email" name="email" style={{ display: "none" }} />
			<input type="hidden" name="key"
						 value={data.formKey}
			/>
			<input type="hidden" name="webform_id" value={data.webformID} />

			<div className={data.infClassName}>
				<SubscribeMessage className="inf-success" style={{ display: "none" }}>
					<Block data={data.subscribeMessage} />
				</SubscribeMessage>

				<div className="inf-content" style={{ display: "flex", flexDirection: "column" }}>
					{content}

					<ConsentCheck data={gen.consentText} action={changeCanSubmit} />

					<div className="inf-submit"
							 style={{ alignSelf: "center" }}>
						{/*<PillButton*/}
						{/*	disabled={!canSubmit}*/}
						{/*	as="input"*/}
						{/*	type="submit"*/}
						{/*	name=""*/}
						{/*	// value={data.validationButton}*/}
						{/*	onClick={() => setSubmitted(true)}*/}
						{/*/>*/}
						<PillButton
							as="button"
							type="submit"
							form={data.webformID}
							onClick={() => setSubmitted(true)}
							disabled={!canSubmit}
						>
							{data.validationButton}
						</PillButton>
					</div>
				</div>
			</div>
		</form>
		// </FormProvider>
	);
};

export default ImForm;
