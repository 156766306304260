import React, { useState } from "react";
import styled from "styled-components";
import Text from "../editorial/Text";
import Spacer from "../layout/Spacer";
import InputItem from "./InputItem";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  appearance: none;
  width: 1.75rem;
  height: 1.75rem;
  min-width: 1.75rem;
  min-height: 1.75rem;
  border: 4px solid ${({ theme }) => theme.colors.main01};
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  border-radius: 50%;

  display: grid;
  place-content: center;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    width: 1.25rem;
    height: 1.25rem;
    border: 3px solid ${({ theme }) => theme.colors.main01};
  }

  ::before {
    content: "";
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.contrast};
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);

    @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
      width: 0.85rem;
      height: 0.85rem;
    }
  }

  :checked::before {
    transform: scale(1);
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column;
  }
`;

const IMRadio = (props) => {
	const { data } = props;
	const [value, setValue] = useState(data.options[0].ID);
	const [otherValue, setOtherValue] = useState("");

	const options = data.options.map((item, index) => {
		if (!item.other) {
			return (
				<Label key={index}>
					<Input
						type="radio" id={data.ID}
						name={`temp-inf[${data.ID}]`}
						data-inf-meta={`${data.ID}`}
						value={item.ID}
						defaultChecked={index === 0}
						onChange={e => {
							setValue(e.target.value);
							setOtherValue("");
						}}
					/>
					<Spacer s="small" />
					<Text s="medium">
						{item.text}
					</Text>
				</Label>
			);
		} else {
			return (
				<div key={index}>
					<Label>
						<Input
							type="radio" id={data.ID}
							name={`temp-inf[${data.ID}]`}
							// data-inf-meta={`${data.ID}`}
							value={item.ID}
							defaultChecked={index === 0}
							onChange={e => {
								setValue(e.target.value);
								setOtherValue("");
							}}
						/>
						<Spacer s="small" />
						<Text s="medium">
							{item.text}
						</Text>
					</Label>
					{
						value === item.ID ?
							<InputItem
								type="text" id={data.ID}
								name={`temp-inf[${data.ID}]`}
								data-inf-meta={`${data.ID}`}
								placeholder={"précisez"}
								value={otherValue}
								onChange={e => setOtherValue(e.target.value)}
								// value={item.ID}
								defaultChecked={index === 0}
							/> : null
					}
				</div>
			);
		}
	});

	return (
		<Comp>
			{data.hasTitle ?
				<Text s="medium">
					{data.title}
					<Spacer s="small" />
				</Text>
				: null}

			<Options className="inf-input inf-input-text">
				{options}
				<input
					style={{ display: "none" }}
					type="text"
					name={`inf[${data.ID}]`}
					data-inf-meta={`${data.ID}`}
					value={otherValue !== "" ? otherValue : value}
					id="checkbox_str"
					// required={data.required ? "required" : null}
					required="required"
					readOnly={true}
				/>
			</Options>
		</Comp>
	);
};

export default IMRadio;
