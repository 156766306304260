import React from "react";
import styled from "styled-components";
import Text from "../editorial/Text";
import Spacer from "../layout/Spacer";

const Comp = styled.div`

`;

const TheArea = styled.textarea`
  height: 12.5rem; // 200px
  padding: 20px 30px;
  width: 31.25rem; // 500px
  outline: none;
  border: 2px solid #ECECF7;
  background-color: ${p => p.error ? p.theme.colors.errorPale : p.theme.colors.titanWhite};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.text.medium.size.regular};
  font-family: ${({ theme }) => theme.text.medium.family}, sans-serif;
  color: ${({ theme }) => theme.colors.main01};

  ::placeholder {
    color: ${({ theme }) => theme.colors.periwinkleGray};
    opacity: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    //height: 2.5rem; // 40px
		width: 100%;
    font-size: ${({ theme }) => theme.text.medium.size.small};
  }
`;

const ImTextarea = (props) => {
	const { data } = props;
	return (
		<Comp>
			{data.hasTitle ?
				<Text s="medium">
					{data.title}
					<Spacer s="small" />
				</Text>
				: null}
			<TheArea
				placeholder={data.placeholder}
				name={`inf[${data.ID}]`}
				data-inf-meta={`${data.ID}`}
				data-inf-error="Merci de renseigner une chaine de caractère"
			>
			</TheArea>
		</Comp>
	);
};

export default ImTextarea;