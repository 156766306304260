import React from "react";
import styled from "styled-components";
import Text from "../editorial/Text";
import Spacer from "../layout/Spacer";
import InputItem from "./InputItem";
import "./form.css";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

const IMInput = (props) => {
		const { data } = props;

		const inputs = data.inputs.map((item, index) => {
			return (
				<React.Fragment key={index}>
					<Label>
						<InputItem
							type={item.type === "regular" ? "text" : item.type}
							name={`inf[${item.ID}]`}
							placeholder={item.placeholder}
							data-inf-meta={`${item.ID}`}
							data-inf-error="Erreur"
							required="required"
							errors={data.errors}
						/>
					</Label>
					<Spacer s="small" />
				</React.Fragment>
			);
		});

		return (
			<Comp>
				{data.hasTitle ?
					<Text s="medium">
						{data.title}
						<Spacer s="small" />
					</Text>
					: null}
				<InputsContainer className="inf-input inf-input-text">
					{inputs}
				</InputsContainer>
			</Comp>
		);
	}
;

export default IMInput;