import React from "react";
import styled from "styled-components";
import Block from "../editorial/Block";
import CheckboxItem from "./CheckboxItem";
import Spacer from "../layout/Spacer";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    align-items: flex-start;
    margin-right: 0;
  }
`;

const ConsentCheck = (props) => {
	const { data, action } = props;
	return (
		<Comp>
			<Label>
				<CheckboxItem
					name="inf[38750]"
					data-inf-meta="38750"
					type="checkbox"
					// checked={data.canSubmit}
					onChange={i => {
						action("consent", i.target.checked);
					}}
					// id={data.ID}
					// name={data.ID}
					// value={i => i.target.checked}
				/>
				<Spacer s="small" />
				{/*<Text s="medium">*/}
					<Block data={data} />
				{/*</Text>*/}
			</Label>
		</Comp>
	);
};

export default ConsentCheck;
