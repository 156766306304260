import React from "react";
import { graphql } from "gatsby";
import PageHeader from "../../components/_global/layout/PageHeader";
import Section from "../../components/_global/layout/Section";
import Block from "../../components/_global/editorial/Block";
import Layout from "../../components/_global/layout/Layout";
import localize from "../../components/_global/localization/localize";
import Spacer from "../../components/_global/layout/Spacer";
// import styled from "styled-components";
import ImForm from "../../components/_global/forms/IMForm";

// const LinkBlocksContainer = styled(Section)`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//
//   @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
//     flex-direction: column;
//   }
// `;

const Parent = ({ data, ...props }) => {
	const { page, form } = data;
	return (
		<Layout title={page.common.title}>
			<PageHeader title={page.common.title} image={page.splashImage} />

			<Section m="large">
				<Block data={page.text} />
				<Spacer s="large" />

				<ImForm data={page.form} gen={form} />
			</Section>
			<Spacer s="large" />
			<Spacer s="large" />
		</Layout>
	);
};

export default localize(Parent);

export const query = graphql`
    {
        page: sanityParent {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            splashImage
            {
                ...
                ImageFragment
            }
            text {
                ...CompleteBlockFragment
            }
            form {
                ...FormFragment
            }
        }
        form: sanityFormComponent
        {
            ...FormComponentFragment
        }
    }
`;
