import React from "react";
import styled from "styled-components";

const Comp = styled.input`
  appearance: none;
  width: 1.75rem;
  height: 1.75rem;
  min-width: 1.75rem;
  min-height: 1.75rem;
  border: 4px solid ${p => p.error ? p.theme.colors.error : p.theme.colors.main01};
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  border-radius: 4px;

  display: grid;
  place-content: center;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    width: 1.25rem;
    height: 1.25rem;
    border: 3px solid ${p => p.error ? p.theme.colors.error : p.theme.colors.main01};
  }

  ::before {
    content: "";
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.contrast};
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);

    @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
      width: 0.85rem;
      height: 0.85rem;
    }
  }

  :checked::before {
    transform: scale(1);
  }
`;

const CheckboxItem = (props) => {
	// console.log(props.error + " - " + props.id);

	return (
		<Comp
			{...props}
		/>
	);
};

export default CheckboxItem;