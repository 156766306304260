import React, { useState } from "react";
import styled from "styled-components";
import Text from "../editorial/Text";
import Spacer from "../layout/Spacer";
import CheckboxItem from "./CheckboxItem";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    align-items: flex-start;
    margin-right: 0;
  }
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
		flex-direction: column;
  }
`;

const ErrorText = styled(Text)`
  //margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.error};
`;

const IMCheckboxes = (props) => {
	const { data } = props;
	const [allValues, setAllValues] = useState([]);
	const [error, setError] = useState(null);

	function handleChange(ID, value, isConditional) {
		setError(false);
		// if the value already is in the array
		if (allValues.find(v => v === value)) {
			// remove value from state array
			setAllValues(allValues.filter(item => item !== value));
		} else {
			// add value to the array
			setAllValues(oldArray => [...oldArray, value]);
		}

		if (isConditional) {
			data.setCanSubmit(ID, value);
		}
	}

	const options = data.options.map((item, index) => {
		return (
			<Label key={index}>
				<CheckboxItem
					error={error}
					type="checkbox"
					// checked={data.canSubmit}
					onChange={i => {
						handleChange(data.ID, item.formConditional ? i.target.checked : item.ID, item.formConditional);
					}}
					id={data.ID}
					name={data.ID}
					// value={i => i.target.checked}
				/>
				<Spacer s="small" />
				<Text s="medium">
					{item.text}
				</Text>
			</Label>
		);
	});

	return (
		<Comp>
			{data.hasTitle ?
				<Text s="medium">
					{data.title}
					<Spacer s="small" />
				</Text>
				: null}

			<Options className="inf-input inf-input-text">
				{options}
				<br />
				<input
					style={{ display: "none" }}
					type="text"
					name={`inf[${data.ID}]`}
					data-inf-meta={`${data.ID}`}
					value={allValues.length > 0 ?
						data.options.length === 1 ?
							"YES" :
							allValues.sort().join(", ") :
						data.options.length === 1 ?
							"NO" :
							""}
					id="checkbox_str"
					// required={data.required ? "required" : null}
					required="required"
					onInvalid={e => {
						setError(true);
					}}
				/>
				{error ?
					<ErrorText s="smaller">
						{data.errors.checkboxesError}
					</ErrorText>
					: null}
			</Options>
		</Comp>
	);
};

export default IMCheckboxes;